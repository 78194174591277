import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem.js'


import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import './styles/index.scss';
import './styles/common/base.css';
import * as ElIcon from '@element-plus/icons-vue'

import '../public/ueditor/ueditor.config.js'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/lang/zh-cn/zh-cn.js'

import https from './utils/http'
import * as filter from "./utils/filter"
import { getDicts } from "./api/dict/data"
import {DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2} from "./utils/sysRuoyi/dict"
import { parseTime, resetForm, addDateRange, selectDictLabel, download, handleTree } from "./utils/sysRuoyi/ruoyi"
//import Pagination from './components/PaginationPev/index.vue'
//import DictTag from './components/DictTag/index.vue'
import JsonEditorVue from 'json-editor-vue3'


const app = createApp(App)
app.config.globalProperties.$filter = filter
Object.keys(ElIcon).forEach((key) => {
	app.component(key, ElIcon[key])
})
// 全局方法挂载
app.config.globalProperties.getDicts = getDicts
app.config.globalProperties.getDictDatas = getDictDatas
app.config.globalProperties.getDictDatas2 = getDictDatas2
app.config.globalProperties.DICT_TYPE = DICT_TYPE
app.config.globalProperties.getDictDataLabel = getDictDataLabel
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.download = download
app.config.globalProperties.handleTree = handleTree




app.config.globalProperties.msgSuccess = function(msg) {
	this.$message({ showClose: true, message: msg, type: "success" });
}

app.config.globalProperties.$equipment = function() {
	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	return flag;
}
app.provide('global', {
	https
})
app.use(store)
	.use(router)
	.use(ElementPlus)
	.use(JsonEditorVue)
	.mount('#app')
