<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>
<script>
import { onMounted, onBeforeMount } from "vue";

import { ElConfigProvider } from "element-plus";
import store from "@/store/index";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
const { body } = document;
// h5适配
const WIDTH = 992; // refer to Bootstrap's responsive design
export default {
    components: {
        [ElConfigProvider.name]: ElConfigProvider,
    },
    setup() {
        let isMobile = null;
        const getMobile = () => {
            const rect = body.getBoundingClientRect();
            isMobile = rect.width - 1 < WIDTH;
            if (isMobile) {
                store.dispatch("app/toggleDevice", "mobile");
            } else {
                store.dispatch("app/toggleDevice", "desktop");
            }
        };
        const resizeHandler = () => {
            if (!document.hidden) {
                const isMobiles = isMobile;
                store.dispatch("app/toggleDevice", isMobiles ? "mobile" : "desktop");
                if (isMobiles) {
                    store.dispatch("app/closeSideBar", {withoutAnimation: true});
                }
            }
        };
        onBeforeMount(() => {
            window.addEventListener("resize", resizeHandler());
        });
        onMounted(() => {
            document.body.style.setProperty("--el-color-primary", "#FF8C19");
            document.body.style.setProperty("--el-color-primary-light-2","#FFA64C");
            document.body.style.setProperty("--el-color-primary-light-3","#FFA64C");
            document.body.style.setProperty("--el-color-primary-light-5","#FF8C19");
            document.body.style.setProperty("--el-color-primary-light-7","#FFCAC6");
            document.body.style.setProperty("--el-color-primary-light-8","#FFDCD9");
            document.body.style.setProperty("--el-color-primary-light-9","#FFF5EB");
            document.body.style.setProperty("--el-color-primary-dark-2","#FF8C19");

            getMobile();
        });
        let locale = zhCn;
        return {
            isMobile,
            locale,
        };
    },
};
</script>

<style lang="less">
</style>
